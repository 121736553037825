import cookie from 'js-cookie'

import brazeInAppConfig from '../../config/brazeInApp.json'

function filterProperties(allowedProperties, event) {
  const filteredEvent = {}

  for (const [key, value] of Object.entries(event)) {
    if (allowedProperties.includes(key)) {
      filteredEvent[key] = value
    }
  }

  return filteredEvent
}

export const getEventProperties = ({
  event,
  userId,
  source,
  trackId,
  anonymousId,
  payload,
}) => {
  const trackingConsented = cookie?.get('trackingConsented') === 'true'
  const {
    eventsToTurnGeneric,
    genericEventName,
    allowedProperties,
    allowedEvents,
  } = brazeInAppConfig

  if (trackingConsented) {
    const attr = { event, source, userId, trackId, anonymousId, ...payload }
    return { event, attr }
  }

  if (eventsToTurnGeneric.includes(event)) {
    event = genericEventName
    return { event, attr: {} }
  }

  if (allowedEvents.includes(event)) {
    const filteredEventProperties = filterProperties(allowedProperties, payload)
    const attr = {
      event,
      source,
      userId,
      trackId,
      anonymousId,
      ...filteredEventProperties,
    }
    return { event, attr }
  }

  return { event: null, attr: null }
}
