import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { clearPersistStorage } from '../../utils/storage'
import Router from 'next/router'
import {
  routeInitialize,
  routeChangeHandler,
} from '@bees-web/nfa-interactive-global'
import store from '../../core/store'

const version = process.env.BUILD_BUILDID

const addNewRelicAttribute = (key: string, value: any) => {
  const newRelicAvailable =
    process.env.NODE_ENV !== 'development' &&
    !!process.env.NEW_RELIC_BROWSER_LICENSE_KEY &&
    !!process.env.NEW_RELIC_APPLICATION_ID &&
    !!window.NREUM

  if (key && newRelicAvailable) {
    window.NREUM.setCustomAttribute(key, value)
  }
}

const getReferrerUrl = (referrer: string): URL | null =>
  referrer && referrer !== '' ? new URL(referrer) : null

const removeImpevaCookies = () => {
  if (process.env.STAGE !== 'prod') {
    const cookieList: string[] = []
    Object.keys(Cookies.get()).forEach((cookie) => {
      if (cookie.includes('incap_ses')) {
        cookieList.push(cookie)
      }
    })
    cookieList.forEach((cookie) => Cookies.remove(cookie))
  }
}

const useTracking = (
  applicationId: string,
  country: string,
  pageKey: string
) => {
  const { dispatch } = store

  useEffect(() => {
    removeImpevaCookies()
    clearPersistStorage(version)
    addNewRelicAttribute('beesApplication', applicationId)

    const referrer = document.referrer
    const origin = window.location.origin
    const pathname = window.location.pathname

    const referrerUrl = getReferrerUrl(referrer)
    dispatch(
      routeInitialize(origin, pathname, referrerUrl ? referrerUrl.pathname : '')
    )
  }, [dispatch, applicationId, country])

  useEffect(() => {
    addNewRelicAttribute('journey', pageKey)
  }, [pageKey])

  useEffect(() => routeChangeHandler(Router, store), [store])
}

export default useTracking
