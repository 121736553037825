import { IReduxAction } from '@bees-web/core/types'
import { TRACK_PAGE } from '../constants'
import { AnyAction, Dispatch } from 'redux'
import cookie from 'js-cookie'

import { uuidv4 } from '@bees-web/nfa-interactive-global'
import { page, track } from './actions'
import { getEventProperties } from './helpers/getEventProperties'

export const trackAction: Function = (action: IReduxAction) => {
  return async (_dispatch: Dispatch<AnyAction>, getState): Promise<void> => {
    const anonymousId = cookie?.get('ajs_anonymous_id')?.replace(/"/g, '')
    const userId = cookie?.get('ajs_user_id')
      ? cookie.get('ajs_user_id').replace(/"/g, '')
      : null
    const language = getState().globals?.user?.language
    const country = getState().globals?.user?.country

    const actionSegmentMap = { [TRACK_PAGE]: page }

    const { type } = action
    const segmentTracking = actionSegmentMap[type]
      ? actionSegmentMap[type]
      : track

    const trackId = uuidv4()
    const hasLocaleState = language && country
    const locale = hasLocaleState ? `${language}-${country}` : null
    segmentTracking(action, trackId, userId, anonymousId, locale)

    const braze = window?.['braze']
    try {
      if (braze) {
        const { event, attr } = getEventProperties({
          event: type,
          userId,
          source: 'braze sdk',
          trackId,
          anonymousId,
          payload: action.payload,
        })
        if (event) {
          braze.logCustomEvent(`NFA ${event}`, attr)
        }
      }
    } catch (err) {
      console.info('error with braze', err)
    }
  }
}
