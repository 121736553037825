import Cookies from 'js-cookie'
import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { ONE_TRUST_TRACKING_GROUP } from '../../constants'
import parseOneTrustGroups from '../../utils/parseOneTrustGroups'

interface UseOneTrust {
  blockTrackersBeforeConsent: boolean
  setTrackingConsented: Dispatch<SetStateAction<boolean>>
  setUserId: Dispatch<SetStateAction<string>>
}

const setTrackingConsentedCookie = (setTrackingCookie) =>
  Cookies.set('trackingConsented', setTrackingCookie, {
    path: '/',
    secure: true,
  })

const useOneTrust = ({
  blockTrackersBeforeConsent,
  setTrackingConsented,
  setUserId,
}: UseOneTrust) => {
  const setCurrentUserId = useState<string | null>(
    Cookies.get('ajs_user_id')
  )[1]
  const [oneTrustLoaded, setOneTrustLoaded] = useState<boolean>(
    typeof window !== 'undefined' && !!window.OneTrust
  )

  useEffect(() => {
    if (process.env.STAGE !== 'prod')
      console.log('[OneTrust] Loaded', !!oneTrustLoaded)
    if (oneTrustLoaded) {
      window.OneTrust.OnConsentChanged(async ({ detail }) => {
        if (process.env.STAGE !== 'prod')
          console.log('[OneTrust] Consent Changed')

        const consented =
          !blockTrackersBeforeConsent ||
          detail.includes(ONE_TRUST_TRACKING_GROUP)
        setTrackingConsented(consented)
        setTrackingConsentedCookie(consented)

        const fullUrl = new URL(
          '/api/auth/consent-change',
          window.location.origin
        )

        await fetch(fullUrl, { method: 'POST' })
        const newAjsUserId = Cookies.get('ajs_user_id')
        setCurrentUserId((prevValue) => {
          if (newAjsUserId !== prevValue) {
            setUserId(newAjsUserId)
            return newAjsUserId
          }
          return prevValue
        })
      })
    }
  }, [oneTrustLoaded])

  useEffect(() => {
    const consent = Cookies.get().OptanonConsent
    const groups = parseOneTrustGroups(consent)
    const consented =
      !blockTrackersBeforeConsent || groups.includes(ONE_TRUST_TRACKING_GROUP)
    setTrackingConsentedCookie(consented)
    setTrackingConsented(consented)
  }, [])

  if (typeof window !== 'undefined')
    window['OptanonWrapper'] = () => {
      setOneTrustLoaded(true)
    }
}

export default useOneTrust
