import { useState, useEffect } from 'react'
import { config } from '../../config'

const enableLogging = process.env.STAGE !== 'prod'

const loadBraze = () => import('@braze/web-sdk')

const changeBrazeUser = (userId: string) => {
  window.braze.automaticallyShowInAppMessages()
  window.braze.changeUser(userId)
  window.braze.openSession()
  // setBrazeUserAttributes('test attribute', 'test value')
}

// const setBrazeUserAttributes = (k, v) =>
//   window.braze.getUser().addToCustomAttributeArray(k, v)

interface UseBraze {
  userId: string
  country: string
  pathname: string
  trackingConsented?: boolean
}

const useBraze = ({
  userId,
  country,
  pathname,
  trackingConsented = false,
}: UseBraze) => {
  const [brazeInitialized, setBrazeInitialized] = useState<boolean>(false)
  const optOutInApp = config[country].braze?.optOutInApp ?? true

  useEffect(() => {
    if (
      pathname === '/' ||
      brazeInitialized ||
      (!trackingConsented && !optOutInApp)
    )
      return

    if (process.env.STAGE !== 'prod') console.log('[useBraze] Initializing SDK')
    setBrazeInitialized(true)
    const { key, host: baseUrl } = config[country].braze
    loadBraze().then((braze) => {
      window.braze = braze
      window.braze.initialize(key, {
        baseUrl,
        enableLogging,
        allowUserSuppliedJavascript: true,
      })
      changeBrazeUser(userId)
    })
  }, [trackingConsented])

  useEffect(() => {
    if (
      typeof window !== undefined &&
      window.braze &&
      window.braze.getUser().getUserId() !== userId
    )
      changeBrazeUser(userId)
  }, [userId])

  return brazeInitialized
}

export default useBraze
