const parseOneTrustGroups = (consent: string): string[] => {
  if (!consent || consent === '') return []
  try {
    return consent
      .split('&')
      .find((option: string) => option.startsWith('groups='))
      .split('=')[1]
      .split(',')
      .map((group: string) =>
        group.split(':')[1] === '1' ? group[0] : undefined
      )
      .filter(Boolean)
  } catch (error) {
    console.info('Error parsing OneTrust groups:', error)
    return []
  }
}

export default parseOneTrustGroups
