/*
This configuration was generated automatically
Please do not change it
STAGE: sit
*/

import type { Config } from '../interfaces'

export const domains = {
  'ar.sit.bees-platform.dev': ['es-AR', 'zh-AR'],
  'be.lab.bees-platform.dev': ['fr-BE', 'nl-BE'],
  'be.sit.bees-platform.dev': ['fr-BE', 'nl-BE'],
  'bo.sit.bees-platform.dev': ['es-BO'],
  'br.lab.bees-platform.dev': ['pt-BR'],
  'br.sit.bees-platform.dev': ['pt-BR'],
  'ca.sit.bees-platform.dev': ['fr-CA', 'en-CA'],
  'cl.sit.bees-platform.dev': ['es-CL'],
  'co.sit.bees-platform.dev': ['es-CO'],
  'de.sit.bees-platform.dev': ['de-DE', 'en-DE'],
  'do.sit.bees-platform.dev': ['es-DO'],
  'ec.sit.bees-platform.dev': ['es-EC'],
  'es.sit.bees-platform.dev': ['es-ES', 'en-ES'],
  'gb.sit.bees-platform.dev': ['en-GB'],
  'gh.sit.bees-platform.dev': ['en-GH'],
  'hn.sit.bees-platform.dev': ['es-HN'],
  'id.sit.bees-platform.dev': ['id-ID'],
  'kr.sit.bees-platform.dev': ['ko-KR'],
  'mx.lab.bees-platform.dev': ['es-MX'],
  'mx.sit.bees-platform.dev': ['es-MX'],
  'nl.sit.bees-platform.dev': ['nl-NL', 'en-NL'],
  'pa.sit.bees-platform.dev': ['es-PA', 'zh-PA'],
  'pe.lab.bees-platform.dev': ['es-PE'],
  'pe.sit.bees-platform.dev': ['es-PE'],
  'ph.sit.bees-platform.dev': ['fil-PH', 'en-PH'],
  'py.sit.bees-platform.dev': ['es-PY'],
  'sv.sit.bees-platform.dev': ['es-SV'],
  'th.sit.bees-platform.dev': ['th-TH', 'en-TH'],
  'tz.sit.bees-platform.dev': ['en-TZ', 'sw-TZ'],
  'ug.sit.bees-platform.dev': ['en-UG', 'sw-UG'],
  'us.lab.bees-platform.dev': ['en-US', 'es-US'],
  'us.sit.bees-platform.dev': ['en-US', 'es-US'],
  'uy.sit.bees-platform.dev': ['es-UY'],
  'za.lab.bees-platform.dev': ['en-ZA'],
  'za.sit.bees-platform.dev': ['en-ZA'],
}

export const config: Config = {
  AR: {
    segment: {
      key: 'XOJNzXscBqjg3Bmldp5QnW1MefcQkRla',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'c0be0122-01b5-46e0-b29e-d3040ff38769-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'a000bee8-fbf8-4082-b20d-a1056d0bacd7',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.Quilmes.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.Quilmes.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  'BE-lab': {
    segment: {
      key: 'PF3Ee9HeaF21LQBOgnryRih7JXck1wyR',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '9b76fe90-4570-4e0a-b6ee-f6e406131874-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '8fafc22c-58bd-40ce-ba75-9ccca20f1917',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.global.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  BE: {
    segment: {
      key: 'PF3Ee9HeaF21LQBOgnryRih7JXck1wyR',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '9b76fe90-4570-4e0a-b6ee-f6e406131874-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: 'cdc12249-60ba-40cf-acd8-d3a61973e0c7',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.global.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  BO: {
    segment: {
      key: 'iLa9kagwgtJQTHCH2oiuvhLvx7DX2XT3',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'be935a9c-79e8-469f-964e-e5d74485f59b-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '39385334-d5f0-41b5-b389-982bf59c7775',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.bolivia.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.bolivia.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  'BR-lab': {
    segment: {
      key: 'pjjXk3RFbZDmlDn5INy50glJmwPWQITd',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'f81003dc-98da-4aea-82ee-8dc94686a6e1-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'cdc12249-60ba-40cf-acd8-d3a61973e0c7',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyC0dUyzJDH5qHctscqzVWBjR_fB6KskUwg',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.parceiro-ambev.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.parceiro-ambev.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  BR: {
    segment: {
      key: 'pjjXk3RFbZDmlDn5INy50glJmwPWQITd',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'f81003dc-98da-4aea-82ee-8dc94686a6e1-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'cdc12249-60ba-40cf-acd8-d3a61973e0c7',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyC0dUyzJDH5qHctscqzVWBjR_fB6KskUwg',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.parceiro-ambev.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.parceiro-ambev.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  CA: {
    segment: {
      key: 'KQ1ZL6XNeldKXs4v2BbIuzoeucVAB4qy',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '035defb4-442d-4ba3-8c1e-68ca6e3db7b6-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '6b559bf5-fa44-4e81-84c0-f21a21d67785',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.canada.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.canada.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  CL: {
    segment: {
      key: 'S5ChaGH9RuBChJwL2YA619qhvlJMHZBB',
      host: 'https://api.segment.io',
    },
    onetrust: {},
    braze: {
      key: '963611fd-12c4-4b05-bdf9-4540d4d8e908',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.chile.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.chile.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  CO: {
    segment: {
      key: 'baucxmLEJpaTy5nOmnwBbL3q5VcHbFQ6',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '09e5f9b9-3f0e-43ab-8d54-b8ab13006dae-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '42714228-3d3d-46f5-b681-280ce0579a53',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyDzsBTAUydi0AnPYVUdRwYwwzWzfJTsGBY',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.colombia.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.colombia.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  DE: {
    segment: {
      key: 'q8CeEREMIua90sBUCsW3UpRurFUcJyay',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '13c0faaf-45e0-4f53-a09c-e79cb0f8590b-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '02137957-6df5-4d48-8cd6-2c5b4f444ebc',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.global.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  DO: {
    segment: {
      key: 'EWItkHoiDuDTi0o2FraZ9lvSkY6MpYuB',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '5ad1670e-def9-4582-adb0-eda7ecf2aece-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '527014e3-e53f-4749-a2b9-12d4456a3da4',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.Mi-Cerveceria.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.Mi-Cerveceria.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  EC: {
    segment: {
      key: 'p9Mh4A8HhOAsuHCVWih5QHnIdhce9n0R',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'a13d857e-6ecd-4550-8308-b967b81da6d2-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '1c59340e-8322-4245-b868-3af23233bb31',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyCdp4jZTSSZbtE_e97IY5D8WFb4DguGFFI',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.ecuador.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.ecuador.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  ES: {
    segment: {
      key: '1ryXLoozVrIscagACuoPuRoyWhTnC6tC',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '47045cf5-74f2-4b07-9632-c1fceaea581a-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: 'f459f30d-eb6e-4845-a041-fea0d9978f72',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.global.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  GB: {
    segment: {
      key: 'pIGE7qmjUaUq3jxfxQjI74gUBXcHVLiL',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '73778fa7-f234-465f-ac52-02ce0e3d6cfc-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: 'e0c7f4de-3baf-447f-a050-4054864d2564',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.unitedkingdom.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.unitedkingdom.qa',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.global.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.global.qa',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.mellifera.qa',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.mellifera.sit',
            paths: ['*'],
          },
        ],
      },
    },
  },
  GH: {
    segment: {
      key: 'YFheepGcs6oblmyH78d8WrArL4ygomz8',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '0190b747-15a9-7bab-9843-cd65e31e2f23-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7f894022-eb17-469c-a07d-dcf0331b92dc',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  HN: {
    segment: {
      key: '7nihCb30DRt4W98ZyH8gdtSNpYbeApAr',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '810a18cd-c922-4a9a-b1c0-3013b5880a23-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '73064aba-225b-4760-b364-44298b6dea79',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.honduras.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.honduras.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  ID: {
    segment: {
      key: 'XUqSvoMcycXbsJnydHHHrLMthz7XUk9f',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '49cbef0a-33eb-4c9b-baad-1c1abcf80ba7-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '924aa6eb-979e-43cd-8d00-7edb63a3a834',
      host: 'sdk.fra-02.braze.eu',
      optOutInApp: false,
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.indonesia.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.indonesia.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  KR: {
    segment: {
      key: 'nCI7XLvB3Ge4yzDrxCdfUKXyJWHs6KmM',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '04f6d6b6-cb30-4ff2-a8bb-c10921c84412',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '27e2265e-d525-4998-b506-e70913687451',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.southkorea.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.southkorea.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  'MX-lab': {
    segment: {
      key: 'myjo3aEkiOCvIYw9zkz9osSesaON44Ek',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'e1f85cf4-e18b-4afe-b3dc-a9c4008ec964-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '0a02d51b-c65e-44bd-9c42-56700dbe3e89',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyDbswZKZ3R_G5wzwxpYWeR9DmDm6Z7u6q4',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.mexico.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.mexico.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  MX: {
    segment: {
      key: 'myjo3aEkiOCvIYw9zkz9osSesaON44Ek',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'e1f85cf4-e18b-4afe-b3dc-a9c4008ec964-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '0a02d51b-c65e-44bd-9c42-56700dbe3e89',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyDbswZKZ3R_G5wzwxpYWeR9DmDm6Z7u6q4',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.mexico.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.mexico.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  NL: {
    segment: {
      key: 'ungQERzRgTkT0ZIV2EchaFDaUSzP3gJ4',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '82e25766-eb5c-42c0-b1fd-7855ec50c79a-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '4f404e93-c64b-4d11-99d5-6bae3d60a1a0',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.global.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.global.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  PA: {
    segment: {
      key: 'w9zQV2O3a23zVHyuAcg5QMQTuM3q9jCr',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '95433d3e-1b0a-4ded-b21b-44f348447428-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '8b9172d0-e751-42a5-b475-9fe08a3ce35b',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.panama.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.panama.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  'PE-lab': {
    segment: {
      key: 'MekBPILzFzPnZUdybMUqGR0ZtD2sjAP4',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'b7d0f091-dfa5-4ab8-80f9-c4f330e1d610-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7b3a546a-da51-42da-86e4-de56dc928b6a',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyA1wmKRkCq_-RBG-LdqJ_KCKBxjcU1H8sc',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.peru.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.peru.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  PE: {
    segment: {
      key: 'MekBPILzFzPnZUdybMUqGR0ZtD2sjAP4',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'b7d0f091-dfa5-4ab8-80f9-c4f330e1d610-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7b3a546a-da51-42da-86e4-de56dc928b6a',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: {
      key: 'AIzaSyA1wmKRkCq_-RBG-LdqJ_KCKBxjcU1H8sc',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.peru.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.peru.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  PH: {
    segment: {
      key: 'LRDqpLJDA7kbhgxfXyNBd0NfnOYrJswJ',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: 'e93c9d75-aa0e-419d-b9b6-61b4d4679aad-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: 'ff6c32da-b235-4dda-8445-aba12a43a8c5',
      host: 'sdk.fra-02.braze.eu',
      optOutInApp: false,
    },
    googleapi: {
      key: 'AIzaSyBIsOwCJ9dBlP71vAqgtwn-797YoYTJY6E',
    },
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.philippines.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.philippines.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  PY: {
    segment: {
      key: 'NWARGUvBALw2gL1L0fergF2qh6qSYyDw',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '3ce6e2f6-9b37-4d63-88b8-a391f79b76c0-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '65dc402e-1d86-43a8-b622-f267561cd587',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.paraguay.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.paraguay.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  SV: {
    segment: {
      key: '1mEFokKITlUMtwziLaYwN1V32yjWIgaX',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'c8108c51-52c7-4b8b-99e2-039ffea21a16-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'dc705099-8a0f-402e-ba89-452aa0fbd5a1',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.elsalvador.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.elsalvador.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  TH: {
    segment: {
      key: 'AqGVDILgkLj1yI6rAkBqM8ETyhMbw29t',
      host: 'https://events.eu1.segmentapis.com',
    },
    onetrust: {
      key: '018dccd2-8882-7ab6-8bde-13eb580c9603-test',
      keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
      autoBlock: true,
      cookieList: false,
      blockTrackersBeforeConsent: true,
    },
    braze: {
      key: '69c129ef-da02-4248-9838-62b5483ff9f5',
      host: 'sdk.fra-02.braze.eu',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.thailand.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.thailand.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  TZ: {
    segment: {
      key: 'TfdFTtCCwh0Bq5XTqvMZcj18g8t6sGKH',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'ca17b4d6-42c2-4f75-93ad-216f49909a0e-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '2a44bdc9-3c28-460a-b1e0-8bacc7cfb6b4',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  UG: {
    segment: {
      key: 'iupAsxa2iWDc1AUXsnpDcWpWBY9twqTT',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'fa07e7e8-806d-4986-b7f8-5091b78fa3ea-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7f894022-eb17-469c-a07d-dcf0331b92dc',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.africa.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  'US-lab': {
    segment: {
      key: 'fyxxxE15gKP4nBxhhEdZAMCFyGPKON20',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'f86d9220-9a08-470e-b71d-fb1c28df4426-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7ec4d223-c2dc-4535-9441-a2867428d5b0',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.unitedstates.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.unitedstates.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  US: {
    segment: {
      key: 'fyxxxE15gKP4nBxhhEdZAMCFyGPKON20',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: 'f86d9220-9a08-470e-b71d-fb1c28df4426-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: '7ec4d223-c2dc-4535-9441-a2867428d5b0',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.unitedstates.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.unitedstates.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  UY: {
    segment: {
      key: 'QJZzqsdp1SBoTEcvCf52lRqfWlcSklzB',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '4d4a3223-1c58-4f90-b642-579063b8e553-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'f01193a6-f0ca-4b76-913d-116775776b65',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.bees.uruguay.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.bees.uruguay.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  'ZA-lab': {
    segment: {
      key: 'RrdjVnzBRqahrw5ApQNyh9e4xdWrS1D3',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '049d3c28-8276-42e9-80d1-837fb542b89d-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'c2ade35a-b65e-4ce7-a612-44e8f855331e',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.sab-connect.sit',
            paths: ['*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.sab-connect.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
  ZA: {
    segment: {
      key: 'RrdjVnzBRqahrw5ApQNyh9e4xdWrS1D3',
      host: 'https://api.segment.io',
    },
    onetrust: {
      key: '049d3c28-8276-42e9-80d1-837fb542b89d-test',
      autoBlock: true,
      cookieList: false,
    },
    braze: {
      key: 'c2ade35a-b65e-4ce7-a612-44e8f855331e',
      host: 'sdk.iad-03.braze.com',
    },
    googleapi: null,
    'apple-app-site-association': {
      applinks: {
        apps: [],
        details: [
          {
            appID: '42BJ74CFXP.com.abi.sab-connect.sit',
            paths: ['NOT /comm-agreements', '*'],
          },
          {
            appID: '42BJ74CFXP.com.abi.sab-connect.qa',
            paths: ['*'],
          },
        ],
      },
    },
  },
}

export { default as dependencies } from './nfa-dependencies'
