import { Middleware } from 'redux'
import { IReduxAction } from '@bees-web/core/types'
import { getTrackableActions } from '../../utils/VL/context/sharedContext'
import { TRACK_PAGE } from '../../constants'
import { trackAction } from '../trackAction'

const defaultTrackableActions = new Set<string>([TRACK_PAGE])

const segmentTrack: Middleware = ({ dispatch }) => {
  const storagedEvents = []
  const EVENT_NAME = 'message'
  const STOP_TRACKING_PARAM = 'disableTracking'
  let trackingStopped = false
  return (next) =>
    (action: IReduxAction): IReduxAction => {
      const { type } = action
      const trackableActions = getTrackableActions()

      if (typeof window !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search)
        const disableTracking = urlParams.get(STOP_TRACKING_PARAM) === 'true'

        if (!trackingStopped && disableTracking) {
          const listener = (event: any): void => {
            if (event.data === 'enableTracking') {
              storagedEvents.forEach((action: IReduxAction) =>
                dispatch(trackAction(action))
              )

              const url = new URL(window.location.href)
              url.searchParams.delete(STOP_TRACKING_PARAM)
              window.history.replaceState({}, '', url.toString())
              window.removeEventListener(EVENT_NAME, listener)
              trackingStopped = false
            }
          }

          window.addEventListener(EVENT_NAME, listener)
          trackingStopped = true
        }
      }

      const result = next(action)
      if (trackableActions.has(type) || defaultTrackableActions.has(type)) {
        if (trackingStopped) storagedEvents.push(action)
        else dispatch(trackAction(action))
      }

      return result
    }
}

export default segmentTrack
